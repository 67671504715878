<!--
 * @Description: 餐厅店铺类型
 * @Author: 琢磨先生
 * @Date: 2022-05-26 15:28:22
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-05 11:01:32
-->


<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-table :data="tableData" ref="table">
      <el-table-column type="expand">
        <template #default="scope">
          <el-row>
            <el-col :span="12">
              <div class="flex-row item-center">
                <el-button
                  size="small"
                  icon="plus"
                  round
                  @click="onAddImageType(scope.row)"
                  >添加图片类型</el-button
                >
              </div>
              <div style="margin-top: 20px">
                <el-tag
                  v-for="item in scope.row.image_types"
                  :key="item.id"
                  style="margin-right: 10px; margin-bottom: 10px"
                  @close="removeImageType(item)"
                  closable
                  >{{ item.name }}</el-tag
                >
              </div>
            </el-col>
          </el-row>
          <!-- <el-image :src="scope.row.pic_url"></el-image> -->
        </template>
      </el-table-column>
      <!-- <el-table-column label="编号" prop="id" width="80"> </el-table-column> -->
      <el-table-column label="名称" prop="name"> </el-table-column>
      <el-table-column label="状态" width="150">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_top">停用</el-tag>
          <el-tag type="success" v-if="!scope.row.is_top">正常</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="创建时间" prop="create_at" width="180">
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-popconfirm title="确定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-dialog
    :title="title"
    v-model="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="名称" prop="name">
        <el-col :span="16">
          <el-input v-model="form.name" placeholder=""></el-input>
        </el-col>
      </el-form-item>
      <el-form-item> </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确 定</el-button
      >
    </template>
  </el-dialog>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      saving: false,
      dialogVisible: false,
      title: "",
      tableData: [],
      form: {},
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      image_form: {},
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.$http.get("admin/v1/DiningRoomType").then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },

    /**
     * 新增、修改
     */
    onEdit(item) {
      this.form = Object.assign({}, item);
      if (item) {
        this.title = "修改";
      } else {
        this.title = "新增";
      }
      this.dialogVisible = true;
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/admin/v1/DiningRoomType/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadData();
                this.dialogVisible = false;
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },

    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http
        .get("admin/v1/DiningRoomType/del?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: res.msg });
            this.loadData();
          }
        });
    },

    /**
     * 添加图片类型
     */
    onAddImageType(item) {
      this.$prompt("", "添加图片类型").then((x) => {
        this.$http
          .post("admin/v1/DiningRoomType/imagetype/edit", {
            dining_type_id: item.id,
            name: x.value,
          })
          .then((res) => {
            if (res.code == 0) {
              // this.loadData();
              item.image_types.push(res.data);
              this.$refs.table.toggleRowExpansion(item, true);
            }
          });
      });
    },

    /**
     *
     */
    removeImageType(item) {
      this.$confirm('确定要删除？','温馨提示').then(()=>{
        this.$http
        .get("admin/v1/DiningRoomType/imagetype/del?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: res.msg });
            this.loadData();
          }
        });
      })
    },
  },
};
</script>